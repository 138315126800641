<style>
  .el-radio__label {
    display: none;
  }
  .text-capitalize .el-input__inner {
    text-transform: uppercase;
  }
  table .el-form-item__content {
    justify-content: center;
  }
  table .el-form-item__error {
    font-weight: 400;
  }
  .el-input-number {
    width: 50%;
  }
  table .el-input-number {
    width: 60%;
  }
  table .el-form-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="formData.productID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldProductData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span v-if="oldProductData.description" class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldProductData.description.length > 500 ? oldProductData.description.substring(0,500)+'...' : oldProductData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form @submit.prevent="submitForm()" :model="formData" :rules="rules" ref="formRef" class="w-100">
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="required fs-6 fw-bold mb-2">{{ productConstants.PRODUCT_NAME }}</label>
                <el-form-item prop="name">
                  <el-input size="large" v-model="formData.name" type="text" :placeholder="productConstants.PRODUCT_NAME" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ productConstants.PRODUCT_SKU }}</label>
                <span class="required ms-1">
                  <el-tooltip class="box-item" effect="dark" :content="productConstants.PRODUCT_SKU_HELP" placement="right" raw-content>
                    <i class="bi bi-question-circle-fill"></i>
                  </el-tooltip>
                </span>
                <el-form-item prop="sku_code" :class="skuExistError != '' ? 'is-error mb-0' : 'mb-0'">
                  <el-input size="large" v-model="formData.sku_code" type="text" class="text-capitalize" :placeholder="productConstants.PRODUCT_SKU"/>
                  <div class="el-form-item__error">{{ skuExistError }}</div>
                </el-form-item>
              </div>
            </div>
          </div> 
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.DESCRIPTION }}</label>
                <el-form-item prop="description">
                  <el-input size="large"
                    v-model="formData.description"
                    :rows="4"
                    type="textarea"
                    :placeholder="generalConstants.DESCRIPTION"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.SORTORDER }}</label>
                <el-form-item prop="sort_order">
                  <el-input-number v-model="formData.sort_order" size="large" controls-position="right"/>
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ generalConstants.STATUS }}</label>
                <el-form-item prop="status">
                  <el-switch v-model="formData.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
              </div>
            </div>
            <div class="col-md-2 fv-row">
              <div class="mb-6">
                <label class="fs-6 fw-bold mb-2">{{ "Generate Base SKU" }}
                  <el-tooltip class="box-item" effect="dark" :content="productConstants.PRODUCT_GENERATE_SKU_HELP" placement="right" raw-content>
                    <i class="bi bi-question-circle-fill"></i>
                  </el-tooltip>
                </label>
                <el-form-item prop="sku_generate_flag">
                  <el-switch v-model="formData.sku_generate_flag" @change="skuGenerateFlag($event)" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4">
              <h4>{{ productConstants.PRODUCT_SIZES }}</h4>
            </div>
            <div class="col-xl-8">
              <button class="mb-2 btn btn-success float-end" @click="onAddItem"><i class="bi bi-plus-circle-fill fs-4"></i> {{ productConstants.ADD_SIZE }}</button>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 fv-row">
              <table class="table table-row-dashed fs-6 datatable border gy-2 gs-2">
                <thead>
                  <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                    <th class="required">{{ generalConstants.TITLE }}</th>
                    <th class="required">{{ generalConstants.HEIGHT }}</th>
                    <th class="required">{{ generalConstants.WIDTH }}</th>
                    <th class="required">
                      {{ productConstants.SIZE_SKU }}
                      <el-tooltip class="box-item" effect="dark" :content="productConstants.SIZE_SKU_HELP" placement="right" raw-content>
                        <i class="bi bi-question-circle-fill"></i>
                      </el-tooltip>
                    </th>
                    <th>{{ productConstants.THICKNESS }}</th>
                    <th>{{ packageConstants.INTERNAL_PACKAGE }}</th>
                    <th>{{ packageConstants.EXTERNAL_PACKAGE }}</th>
                    <th>{{ generalConstants.SORTORDER }}</th>
                    <th>{{ generalConstants.DEFAULT }}</th>
                    <th>{{ generalConstants.STATUS }}</th>
                    <th>{{ generalConstants.ACTIONS }}</th>
                  </tr>
                </thead>
                <tbody class="fw-bold text-gray-600">
                  <tr v-for="(sizes, index) in formData.sizeData" :key="index" v-bind:id="'row_'+index" align="center">
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.size'"
                        :rules="{
                          required: true,
                          message: productConstants.TITLE_REQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="sizes.size" :placeholder="generalConstants.TITLE" maxlength="20" :tabindex="index"/>
                      </el-form-item>
                      <el-form-item class="d-none">
                        <el-input size="large" type="hidden" v-model="sizes.size_id" />
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.height'"
                        :rules="{
                          required: true,
                          message: productConstants.HEIGHT_REQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="sizes.height" :placeholder="generalConstants.HEIGHT" maxlength="20" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.width'"
                        :rules="{
                          required: true,
                          message: productConstants.WIDTH_REQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="sizes.width" :placeholder="generalConstants.WIDTH" maxlength="20" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.sku_code'"
                        :rules="{
                          required: true,
                          validator: skuValidate,
                          requiredmessage: productConstants.SIZE_SKU_REQUIRED,
                          trigger: 'change',
                        }">
                        <el-input size="large" v-model="sizes.sku_code" class="text-capitalize" :placeholder="productConstants.SIZE_SKU" maxlength="20" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.thickness'">
                        <el-input size="large" v-model="sizes.thickness" :placeholder="productConstants.THICKNESS" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.internal_package_id'">
                        <el-select :disabled="internalPackageDisabled" v-model="sizes.internal_package_id" :placeholder="packageConstants.INTERNAL_PACKAGE" :tabindex="index" size="large">
                          <el-option :label="packageval.package_code" :value="packageval.master_package_id" :key="packageval.master_package_id"  v-for="(packageval, packagekey) in internalPackages"></el-option>
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.external_package_id'">
                        <el-select :disabled="externalPackageDisabled" v-model="sizes.external_package_id" :placeholder="packageConstants.EXTERNAL_PACKAGE" :tabindex="index" size="large">
                          <el-option :label="packageval.package_code" :value="packageval.master_package_id" :key="packageval.master_package_id"  v-for="(packageval, packagekey) in externalPackages"></el-option>
                        </el-select>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.sort_order'">
                        <el-input-number v-model="sizes.sort_order" value="0" controls-position="right" :tabindex="index" size="large"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.is_default'">
                        <el-radio v-model="sizes.is_default" :label="1" @change="changeSizeDefaultVal($event, index)" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item :prop="'sizeData.' + index + '.status'">
                        <el-switch v-model="sizes.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" :tabindex="index"/>
                      </el-form-item>
                    </td>
                    <td>
                      <el-form-item  :key="'actions'+index" >
                        <span v-if="sizes.is_default=='1'">-</span>
                        <div v-else>
                          <button type="button" @click.prevent="deleteItem(index)" :tabindex="index" class="btn btn-icon btn-light btn-sm me-2">
                            <span class="bi bi-trash text-danger" name ="delete" :id="index" ></span>
                          </button>
                        </div>
                      </el-form-item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 fv-row mb-5">
              <button class="btn btn-primary me-2" :disabled="loading" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" @click="submitForm(formRef, 'save')">{{ generalConstants.SAVE }}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button class="btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{ generalConstants.SAVEANDBACK }}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button class="btn btn-primary me-2" v-if="permission.isAssignOptionAllowed" :disabled="saveAndAssignOptionLoader" @click="submitForm(formRef, 'save_additional')">{{ productConstants.SAVEANDOPTION }}<span v-if="saveAndAssignOptionLoader" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button class="btn btn-secondary me-2" @click.prevent="$router.push('/products')">{{ generalConstants.CANCEL }}</button>
            </div>
          </div> 
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { skuValidate, checkNumberWithoutRequired } from "@/composable/common-validation.js";
import { ElMessageBox } from 'element-plus';
import { formatText } from "@/core/helpers/common";
import { useRoute } from "vue-router";
import globalConstant from "@/core/data/globalConstant.js";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from '../../composable/useEventBus';
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "add-new-product",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const route = useRoute();
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const saveAndAssignOptionLoader = ref(false);
    const pageLoading = ref(false);
    const internalPackages = ref([]);
    const externalPackages = ref([]);
    const generalConstants = globalConstant.general;
    const productConstants = globalConstant.product;
    const packageConstants = globalConstant.package;
    const internalPackageDisabled = ref(false);
    const externalPackageDisabled = ref(false);
    const skuExistError = ref('');
    const oldProductData = reactive({
      name: '',
      description: ''
    });
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName == "save_back"){
        submitForm(formRef, 'save_back', true)
      }else if(actionName == "save_additional"){
        submitForm(formRef, 'save_additional', true)
      }else if(actionName == "cancel"){
        router.push('/products');
      }
    })

    const skuGenerateFlag = (val) => {
      if(val == true){
        internalPackageDisabled.value = false;
        externalPackageDisabled.value = false;
      }else{
        internalPackageDisabled.value = true;
        externalPackageDisabled.value = true;
      }
    }

    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isAssignOptionAllowed: false
    })
    const formData = reactive({
      productID: 0,
      name: "",
      price: "",
      description: "",
      sort_order:0,
      status: true,
      sku_generate_flag: true,
      sku_code: "",
      sizeData: [
          {
            size_id: "",
            size: "",
            height: "",
            width: "",
            sort_order: 0,
            is_default: 1,
            status: true,
            sku_code: "",
            deleteConfirm: false,
            internal_package_id: "",
            external_package_id: "",
            thickness: ""
          },
        ],
    });

    //initialize table data
    const tableData = reactive([
      { 
        sizeData: [
          {
            size_id: "",
            size: "",
            height: "",
            width: "",
            sort_order: 0,
            is_default: 0,
            status: true,
            sku_code: "",
            deleteConfirm: false,
            internal_package_id: "",
            external_package_id: "",
            thickness: ""
          },
        ],
      }
    ])

    //Set default value for sizes
    const changeSizeDefaultVal = (val, k) => {
      formData.sizeData.forEach((obj) => {
        obj.is_default = 0;
      });
      formData.sizeData[k].is_default = val;
    }

    //Add new record for size data
    const onAddItem = () => {       
      formData.sizeData.push({
        size_id: "",
        size: "",
        height: "",
        width: "",
        sort_order: 0,
        is_default: 0,
        sku_code: "",
        status: true,
        deleteConfirm: false,
        internal_package_id: "",
        external_package_id: "",
        thickness: ""
      });
    }

    //Delete Product Master Attribute Record
    const deleteArr = [];
    const deleteItem = async (index) => {
      if (index > -1) {
        if (formData.sizeData[index].size_id) {
          let deleteBtnClass = document.getElementById(index).getAttribute("name");
          if (deleteBtnClass == "delete") {
            document.getElementById(index).setAttribute("class", "fa fa-undo text-primary");
            document.getElementById(index).setAttribute("name", "undo");
            document.getElementById("row_" + index).setAttribute("class", "bg-danger");
          } else {
            document.getElementById(index).setAttribute("class", "bi bi-trash text-danger");
            document.getElementById(index).setAttribute("name", "delete");
            document.getElementById("row_" + index).setAttribute("class", "");
          }
          if (formData.sizeData[index].action == "delete") {
            formData.sizeData[index].action = "edit";
          } else {
            formData.sizeData[index].action = "delete";
            deleteArr.push(index);
          }
        } else {
          formData.sizeData.splice(index, 1);
        }
      }
    };

    const rules = reactive({
      name: [
        {
          required: true,
          message: productConstants.PRODUCT_NAME_REQUIRED,
          trigger: "change",
        },
      ],
      price: [
        {
          validator: checkNumberWithoutRequired,
          required: true,
        },
      ],
      sku_code: [
        {
          validator: skuValidate,
          required: true,
          requiredmessage: productConstants.PRODUCT_SKU_REQUIRED,
          trigger: "change",
        },
      ],
    });

    //Store product data
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction)?formEl.value:formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;

        if(isToolbarButtonsSubmitAction) emitEvent('loader',{"save_loader":true,"action":btnName})
        else
          if(btnName == "save") loading.value = true
          else if(btnName == "save_back") loadingback.value = true
          else if(btnName == "save_additional") saveAndAssignOptionLoader.value = true

        let count = -1;
        formData.sizeData.forEach((obj) => {
          obj.size == "" ? (obj.size = null) : obj.size;
          obj.height == "" ? (obj.height = null) : obj.height;
          obj.weight == "" ? (obj.weight = null) : obj.weight;
          obj.sort_order == "" ? (obj.sort_order = 0) : obj.sort_order;
          obj.sku_code == "" ? (obj.sku_code = null) : obj.sku_code;
          obj.is_default == "" ? (obj.is_default = 0) : obj.is_default;
          obj.size_sku == "" ? (obj.product_sku_id = null) : obj.product_sku_id;
          obj.thickness == "" ? (obj.thickness = null) : obj.thickness;
          obj.status == 1 ? (obj.status = true) : (obj.status = false);
          if (formData.productID > 0) {
            count++;
            if (obj.size_id && obj.action != "delete") {
              obj.action = "edit";
            }
            if (!obj.size_id) {
              obj.action = "add";
            }
            if(obj.action == "delete") {
              document.getElementById(count).setAttribute("class", "bi bi-trash text-danger");
              document.getElementById(count).setAttribute("name", "delete");
              document.getElementById("row_" + count).setAttribute("class", "");
            }
          }
          delete obj.deleteConfirm;
        });
        let addParams = {
          _method: formData.productID > 0 ? "PUT" : null,
          productId: formData.productID !== undefined ? formData.productID : '',
          name: formData.name,
          price: formData.price,
          description: formData.description,
          sort_order: formData.sort_order,
          status: formData.status,
          sku_generate_flag: formData.sku_generate_flag,
          sku_code: formData.sku_code,
          product_sizes: formData.sizeData,
        };
        let apiURL = "products";
        if(formData.productID > 0) {
          apiURL = "products/" + formData.productID;
        }
        ApiService.post(apiURL, addParams)
        .then(({ data }) => {
          if(data) {
            if (formData.productID == 0 || formData.productID === undefined)
              formData.productID = data.data.id;

            notificationFire(data.message, generalConstants.NOTIFY_SUCCESS)
            oldProductData.name = formData.name;
            oldProductData.description = formData.description;
            if (btnName == 'save') {
              (isToolbarButtonsSubmitAction)?emitEvent('loader',{"save_loader":false,"action":btnName}):loading.value = false;

              if (data.data.sizes) {
                let diff = formData.sizeData.length > 0 ? formData.sizeData.length - data.data.sizes.length : 0;
                data.data.sizes.forEach((obj) => {
                  formData.sizeData[diff].size_id = obj;
                  formData.sizeData[diff].deleteConfirm = true;
                  diff++;
                });
              }

              deleteArr.forEach((obj) => {
                formData.sizeData.splice(obj, 1);
              });
              if (typeof route.params.id === "undefined") {
                setCurrentPageBreadcrumbs(formatText('Edit Product'), [{"name": "Products", "path": "products"}, { name: formatText('Edit Product'), active: true }]);
                router.push({ name: 'edit-product', params: { id: formData.productID } })
              }
            } else if (btnName == 'save_back') {
              (isToolbarButtonsSubmitAction)?emitEvent('loader',{"save_loader":false,"action":btnName}):loadingback.value = false;
              router.push({ name: 'products'});
            }
            else if (btnName == 'save_additional') {
              (isToolbarButtonsSubmitAction) ? emitEvent('loader', {"save_loader" : false, "action" : btnName}) : saveAndAssignOptionLoader.value = false;
              router.push({ name: 'assign-product-options', params: { id: formData.productID }});
            }
            skuExistError.value = '';
          }
        })
        .catch((error) => {
          if(isToolbarButtonsSubmitAction) emitEvent('loader',{"save_loader":false,"action":btnName})
          else
            if(btnName == "save") loading.value = false
            else if(btnName == "save_back") loadingback.value = false
            else if(btnName == "save_additional") saveAndAssignOptionLoader.value = false
            let message='';
            let response = error.response;
            if (typeof (error.response) !== 'undefined') {
              message = error.response.data.message;
            } else if (typeof (error.message) !== 'undefined') {
              message = error.message;
            } else {
              message = generalConstants.NOTIFY_WRONG_MESSAGE;
            }
            if(response.status == 422 && message == 'Sku code is already exist.'){
              skuExistError.value = message[0];
            }else{
              notificationFire(message, generalConstants.NOTIFY_ERROR);
            }
          });
      });
    };

    //Fetch product data
    const getProductData = async() => {
      pageLoading.value = true;
      await ApiService.query("products/" + formData.productID)
      .then(({ data }) => {
        pageLoading.value = false;
        if (data.data) {
          let productData = data.data
          formData.name = oldProductData.name = productData.name;
          formData.price = productData.price;
          formData.description = oldProductData.description = productData.description;
          formData.sort_order = productData.sort_order==null?0:productData.sort_order;
          formData.sku_code = productData.sku_code;
          formData.status = (productData.status == 1) ? true : false;
          formData.sku_generate_flag = (productData.sku_generate_flag == 1) ? true : false;
          formData.sizeData=[];
          formData.sizeData=productData.sizes;
          formData.sizeData.forEach((obj) => {
            obj.status = (obj.status == 1) ? true : false;
            obj.shipping_package_id = (obj.size_sku != null && obj.size_sku.shipping_package != null) ? obj.size_sku.shipping_package.shipping_package_id : "";
            obj.internal_package_id = (obj.size_sku != null && obj.size_sku.shipping_package != null && obj.size_sku.shipping_package.internal_package_id > 0) ? obj.size_sku.shipping_package.internal_package_id : "";
            obj.external_package_id = (obj.size_sku != null && obj.size_sku.shipping_package != null && obj.size_sku.shipping_package.external_package_id > 0) ? obj.size_sku.shipping_package.external_package_id : "";
            obj.deleteConfirm = true;
          });
          skuGenerateFlag(formData.sku_generate_flag);
        } else {
          notificationFire("Resource Not Found.", generalConstants.NOTIFY_ERROR);
          router.push({ name: 'products' })
        }
      })
      .catch((error) => {
        pageLoading.value = false;
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    //Get active Master package list
    const getMasterPackage = async() => {
      await ApiService.query("get-active-master-package")
      .then(({ data }) => {
        if(data.data) {
          let response = '';
          data.data.map(function (element) {
            if(element.package_type == '1') {
              response = externalPackages.value;
            } else {
              response = internalPackages.value;
            }
            response.push({
              'master_package_id': element.master_package_id,
              'package_type': element.package_type,
              'package_code': element.package_code
            });
          });
        }
      })
      .catch((error) => {
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" : generalConstants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader':false, 'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed) }, {"name": generalConstants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false },{"name": productConstants.SAVEANDOPTION, 'action': 'save_additional','class': 'btn-primary me-3','isLoader':false,'isAllowed': permission.isAssignOptionAllowed}, {"name": generalConstants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }

    //Set breadcrumbs 
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Products", "path": "products"}, { name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createProduct") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateProduct") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("assignOptionProduct") || role == "Super Admin")
        permission.isAssignOptionAllowed = true;
      formData.productID = router.currentRoute.value.params.id;
      getMasterPackage();     
      if(formData.productID > 0 ) {
        await getProductData();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    
    return {
      router,
      formRef,
      loading,
      loadingback,
      saveAndAssignOptionLoader,
      formData,
      tableData,
      rules,
      submitForm,
      getProductData,
      changeSizeDefaultVal,
      onAddItem,
      deleteItem,
      skuValidate,
      deleteArr,
      pageLoading,
      internalPackages,
      externalPackages,
      checkNumberWithoutRequired,
      generalConstants,
      productConstants,
      packageConstants,
      permission,
      skuGenerateFlag,
      internalPackageDisabled,
      externalPackageDisabled,
      oldProductData,
      skuExistError
    };
  },
};

</script>